<template>
  <el-dialog
    style="
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      height: 70%;
      width: 65%;
    "
    :lock-scroll="false"
    :show-close="false"
  >
    <div class="soft-bor-1"></div>
    <div class="call-content">
      <div class="call-img"></div>
      <div class="call-title">
        <p class="call-title-1">
          我们的技术人员将根据您的生产需求，为您定制专业的解决方案！
        </p>
        <p class="call-title-2">
          针对不同的应用场景，如内容创作、游戏设计、工程、科学研究和人工智能等，定制不同方案。
        </p>
        <p class="call-title-3">
          我们使用柔性生产供应链体系，灵活多样，可以实现快速交付和部署！<br/>
          14年品质保证，为您保驾护航！
        </p>
        <div class="call-title-4">
          <p @click="handleShow">在线联系我们，获得专业建议</p>
          <p @click="workOrderSubmite">提交产品需求，保持沟通</p>
        </div>
      </div>
      <el-dialog
        style="
          border-radius: 20px;
          width: 27%;
          height: 68%;
          position: relative;
        "
        :show-close="false"
        destroy-on-close="true"
        v-model="isVisible"
      >
        <workOrder
          @closeOpen="closeOpen"
          style="position: absolute; left: -63px; top: 30px"
        />
      </el-dialog>
    </div>
  </el-dialog>
</template>
<script setup>
import { ref } from "vue";
import workOrder from '@/views/workOrder'
import emitter from "@/utils/eventBus";
const handleShow = () => {
  emitter.emit("onlineClient");
  emitter.emit("closeBeside");
};
const isVisible = ref(false);
const workOrderSubmite = () => {
  isVisible.value = true;
};
const closeOpen = () => {
  isVisible.value = false;
};
</script>
<style scoped lang="less">
.soft-bor-1 {
  border-top: 1px solid #e1e1e1;
  margin-left: 9px;
  margin-right: 50px;
  margin-bottom: 40px;
}
.call-content {
  padding: 45px 0 76px 79px;
  display: flex;
  .call-img {
    width: 366px;
    height: 464px;
    background: url("../../assets/images/lxwom.png");
    background-position: center;
    background-repeat: no-repeat;
  }
  .call-title {
    .call-title-1 {
      margin-left: 52px;
      margin-right: 102px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .call-title-2 {
      margin-left: 52px;
      margin-right: 102px;
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .call-title-3 {
      margin-left: 52px;
      margin-right: 102px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .call-title-4 {
      display: flex;
      margin-top: 144px;
      margin-left: 46px;
      p:nth-child(1) {
        width: 300px;
        height: 60px;
        line-height: 60px;
        background: rgba(255, 105, 0, 0.1);
        border: 1px solid #ce1200;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 400;
        color: #ce1200;
        text-align: center;
        cursor: pointer;
      }
      p:nth-child(2) {
        width: 300px;
        height: 60px;
        line-height: 60px;
        background: #ce1200;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>
